registerProcessor(
  'LogicProcessor',
  class ClockProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'a',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
        {
          name: 'b',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    process(
      _: Float32Array[][],
      [[and], [or], [xor]]: Float32Array[][],
      { a: [a], b: [b] }: { [param: string]: Float32Array },
    ): boolean {
      const aa = a > 0;
      const bb = b > 0;
      and[0] = aa && bb ? 1 : 0;
      or[0] = aa || bb ? 1 : 0;
      xor[0] = (aa || bb) && !(aa && bb) ? 1 : 0;
      return true;
    }
  },
);
